// HOME : 1
.btn-custom{
  border: transparent;
  &:hover, &:focus, &:active, &.active, &:focus-visible{
    color: $white !important;
    background-color: $custom !important;
  }
}
.home-center {
  display: table;
  width: 100%;
  height: 100%;
}

.home-desc-center {
  display: table-cell;
  vertical-align: middle;
}

.home-1-bg {
  background-image: url("../images/gray-home.jpg");
  position: relative;
  background-size: cover;
  height: 100vh;
  background-position: center;
}

.home-1-shape {
  background: #06c25126;
  height: 9rem;
  position: absolute;
  bottom: -1px;
  padding: 0;
  right: 0;
  left: 0;
}

.home-1-title {
  z-index: 1;
  position: relative;
}

// HOME : 2
.home-2-bg {
  background-image: url("../images/home-2-bg.png");
  background-color: #0e6cff;
  position: relative;
  background-size: cover;
  height: 100vh;
  background-position: center center;
}

.home-2-title {
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.home-2-img {
  z-index: 1;
  position: relative;
}

.home-2-bottom-img {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: block;
  opacity: 0.15;
}

.mover-img {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
  }
}

@keyframes mover {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
  }
}

// HOME : 3
.home-3-bg {
  background-image: url("../images/home-3-bg.png");
  background-color: $primary;
  position: relative;
  background-size: cover;
  height: 100vh;
  background-position: center center;
}

.home-3-shape {
  position: absolute;
  padding: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.play-icon-circle {
  background: $custom;
  height: 46px;
  width: 46px;
  line-height: 46px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
}

.home-3-content {
  z-index: 1;
  position: relative;
}

.video-box {
  width: 100%;
}

.home-modal {
  border: 0;
  background-color: transparent;
  .btn-close {
    width: 24px;
    height: 24px;
    background-color: rgba($white, 0.5);
    border-radius: 4px;
    position: relative;
    top: 60px;
    right: 3px;
    z-index: 3;
    &:hover,
    &:focus {
      opacity: 0.5;
    }
  }
  video {
    &:focus {
      outline: none;
    }
  }
}

// HOME : 4
.home-4-bg {
  background-image: url("../images/home-4-bg.jpg");
  position: relative;
  background-size: cover;
  height: 100vh;
}

.home-4-content {
  max-width: 450px;
}

.subcribe-form input {
  padding: 12px 18px;
  background-color: #fff;
  width: 68%;
  border: none;
  border-radius: 4px;
  outline: none !important;
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.2);
}

.video-button {
  position: absolute;
  right: 0px;
  left: 0;
  margin: 0 auto;
  display: block;
  text-align: center;
  bottom: 50%;
  transform: translateY(50%);
  .play-icon-circle {
    background: #ffffff;
    height: 70px;
    width: 70px;
    line-height: 70px;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    font-size: 30px;
    box-shadow: 0 0 0 9px rgba(255, 255, 255, 0.15);
    &:after {
      content: "";
      background: rgba(255, 255, 255, 0.1);
      height: 106px;
      width: 106px;
      border-radius: 50%;
      position: absolute;
      left: 0px;
      right: 0;
      margin: 0 auto;
      bottom: 50%;
      transform: translateY(50%);
    }
  }
}

// HOME : 5
.home-5-bg {
  background-image: url("../images/home-5-bg.jpg");
  position: relative;
  background-size: cover;
  height: 100vh;
  background-position: center center;
}

.home-5-content {
  z-index: 1;
  position: relative;
}

// HOME : 6
.home-6-bg {
  background-image: linear-gradient(45deg, $primary 0%, #039bea 100%);
  position: relative;
  background-size: cover;
  height: 100vh;
  background-position: center center;
}

.home-6-content {
  background-image: url("../images/home-6-img-bg.png");
  position: relative;
  background-size: cover;
  background-position: center center;
}

// HOME : 7
.home-7-bg {
  position: relative;
  background-size: cover;
  padding: 280px 0px 210px 0px;
  background-position: center center;
}
.search-form {
  input {
    padding: 12px 20px;
    width: 70%;
    font-size: 17px;
    color: #4c5667 !important;
    border: none;
    outline: none !important;
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 30px;
  }
  form {
    position: relative;
    max-width: 560px;
    margin: 0px auto;
  }
}

.back-slide {
  img {
    width: 100%;
    top: 0;
    position: absolute;
  }
}

// HOME : 8
.home-8-bg {
  position: relative;
  background-size: cover;
  height: 100vh;
  background-position: center center;
}
.home-8-content {
  .play-icon-circle {
    height: 64px;
    width: 64px;
    line-height: 64px;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    font-size: 28px;
  }
  a {
    span {
      font-size: 16px;
      padding-left: 3px;
      position: relative;
      top: -5px;
    }
  }
}

.home-8-title .letter {
  display: inline-block;
  line-height: 1em;
}

#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}


// Home-9 //
.home-9{
  position: relative;
  padding: 250px 0px 200px;
  height: auto;
  .bg-overlay{
    opacity: 0.8;
  }
  .btn-orange{
    background-color: #ff8700;
  }
  .submit-btn{
    padding: 8px 30px;
    font-size: 14px !important;
  }
  .form-select,input{
    font-size: 14px;
  }
}

@media (max-width:575px){
  .home-9{
    .submit-btn, input{
      border-radius: 0px !important;
    }
  }
}